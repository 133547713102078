import { TOKEN_KEY, API_URL } from "./constants";
import axios from "axios";
import jwt_decode from 'jwt-decode';

export const axiosInstance = axios.create();
export const apiFunction: any = {
  getUserData: async () => {
    if (localStorage.getItem(TOKEN_KEY)) {
      const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
      return (decoded && decoded.user) ? decoded.user : []
    } else {
      return [];
    }

  },
  permission: async () => {
    if (localStorage.getItem(TOKEN_KEY)) {
      const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
      return (decoded && decoded.group?.isAdmin) ? 'admin' : decoded.group?.isUser ? 'user' : 'user'
    } else {
      return 'user';
    }

  },
}
