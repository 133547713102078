import {
    Create,
    Form,
    Input,
    useForm,
} from '@pankod/refine-antd';

import {ICategory} from 'interfaces';

export const CategoryCreate = () => {
    const { formProps, saveButtonProps } = useForm<ICategory>({
    });

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="horizontal">
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
            </Form>
        </Create>
    );
};
