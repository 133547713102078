import {
    Col,
    Create,
    Form, getValueFromEvent,
    Input, Row, Select, Upload,
    useForm, useSelect,
} from '@pankod/refine-antd';

import React from 'react';
import {ITicket, ITicketCategory, ITicketPriority, ITicketRow, ITicketStatus} from '../../interfaces';
import {useCreate, useGetIdentity, useNavigation} from '@pankod/refine-core';
import {API_URL} from '../../constants';

export const TicketCreate = () => {
    const {list} = useNavigation();
    const { data: identity } = useGetIdentity<{ id: number; fullName: string}>();
    const { formProps, saveButtonProps } = useForm<ITicket>({
    });

    const {selectProps} = useSelect<ITicketPriority>({
        resource: 'ticket-priorities',
        optionLabel: 'description',
        optionValue: 'id'
    })

    const {selectProps: selectCategory} = useSelect<ITicketCategory>({
        resource: 'ticket-categories',
        optionLabel: 'description',
        optionValue: 'id'
    })

    const {selectProps: selectStatus, queryResult} = useSelect<ITicketStatus>({
        resource: 'ticket-statuses',
        optionLabel: 'description',
        optionValue: 'id'
    })

    const createTicket = useCreate<ITicket>();
    const createTicketRow = useCreate<ITicketRow>();
    const finish = (values: any) => {
        console.log(values);
        const status: any = queryResult.data?.data.filter((x: any) => x.isOpen)[0];
        console.log(status);
        createTicket.mutateAsync({
            resource: 'tickets',
            successNotification: false,
            values: {
                oggetto: values.oggetto,
                isClosed: false,
                ticket_priority: values.ticketPriority,
                ticket_category: values.ticketCategory,
                ticket_status: status.id,
                fromUser: identity?.id,
                dataApertura: new Date().toISOString()
            }
        }).then( (res: any) => {
            if (res) {
                createTicketRow.mutateAsync({
                    resource: 'ticket-rows',
                    values: {
                        messaggio: values.message,
                        ticket: res.data.data.id,
                        fromUser: identity?.id,
                        file: values.files.map((f: any) => f.response[0].id)
                    }
                }).then( res => {
                    console.log(res);
                    list('tickets')
                })
            }
        })
    }
    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps}
                  layout="vertical"
                  onFinish={finish}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Oggetto"
                            name="oggetto"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Priorità"
                            name="ticketPriority"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...selectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Categoria"
                            name="ticketCategory"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...selectCategory} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="Messaggio"
                            name="message"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input.TextArea rows={15} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="Image">
                            <Form.Item
                                name="files"
                                valuePropName="fileList"
                                getValueFromEvent={getValueFromEvent}
                                noStyle
                            >
                                <Upload.Dragger
                                    name="files"
                                    action={`${API_URL}/api/upload`}
                                    listType="picture"
                                    maxCount={5}
                                    multiple
                                >
                                    <p className="ant-upload-text">
                                        Drag & drop a file in this area
                                    </p>
                                </Upload.Dragger>
                            </Form.Item>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};
