import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useGetIdentity, useList} from '@pankod/refine-core';
import {
    Row,
    Col,
    Card,
    Typography,
    Space, Table, useTable, DateField, EditButton, Icons, DeleteButton, TagField
} from '@pankod/refine-antd';
import {IShow, ITicket, IUser} from '../../interfaces';
import DocViewer, {DocViewerRenderers} from 'react-doc-viewer';
import {apiFunction} from '../../apiFuntion';
import {API_URL} from '../../constants';
import {UserContext} from '../../contexts/UserContext';
import dayjs from 'dayjs';

export const DashboardPage: React.FC = () => {
    const {value} = useContext(UserContext);
    const [userPermission, setUserPermission] = value;
    const [file, setFile] = useState<IShow[]>([]);
    const mountedRef = useRef(true)
    const listShow = useList<IShow>({
        resource: 'shows',
        queryOptions: {
            enabled: false
        },
        metaData: {
            populate: ["user"],
        },
        config: {
            filters: [
                {
                    field: "user][id]",
                    operator: "eq",
                    value: userPermission?.id ,
                },
            ],
            sort: [
                {
                    field: 'updatedAt',
                    order: 'asc'
                }
            ]
        },
    });

    const {tableProps, setFilters, filters, tableQueryResult} = useTable<ITicket>({
        resource: 'tickets',
        metaData: {
            populate: 'ticket_rows.fromUser, ticket_rows.toUser, ticket_rows, ticket_rows.file, fromUser, ticket_category, ticket_priority, ticket_status'
        },
        queryOptions: {
            enabled: true
        },
        initialPageSize: 3,
        permanentFilter: [
            {
                field: 'fromUser][id]',
                operator: 'eq',
                value: userPermission?.group?.isUser ? userPermission.id : null
            }
        ],
        permanentSorter: [
            {
                field: 'dataApertura',
                order: 'desc'
            }
        ]
    });

    useEffect( () => {
            if (!userPermission.group.isAdmin) {
                listShow.refetch().then( (s: any ) => {
                    setFile([]);
                    if (s.data.data && s.data.data.length > 0) {
                        for (let sh of s.data.data) {
                            console.log(sh);
                            setFile((prev: any) => [...prev, sh]);
                        }
                        if (!mountedRef.current) return null
                    }
                });
            }
        return () => {
            mountedRef.current = false
        }
    }, [])

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Table {...tableProps} rowKey="id" size={'small'}>
                        <Table.Column
                            dataIndex="oggetto"
                            title="Oggetto"/>
                        <Table.Column
                            dataIndex="dataApertura"
                            title="Data"
                            render={(value, record, index) => (<DateField value={dayjs(value)} format={'DD/MM/YYYY HH:mm'}/>)}
                        />
                        <Table.Column
                            dataIndex="ticket_category"
                            title="Categoria"
                            render={(value, record, index) => (value.description)}
                        />
                        <Table.Column
                            dataIndex="ticket_priority"
                            title="Priorità"
                            render={(value, record, index) => (value.description)}
                        />
                        <Table.Column
                            dataIndex="ticket_status"
                            title="Stato"
                            render={(value, record, index) => (
                                <TagField value={value.description} color={value.isClose ? 'red' : value.isUpdate ? 'yellow' : 'green'} />
                            )}
                        />
                    </Table>
                </Col>
            </Row>
            { file &&
            <Row gutter={24} justify="start"  align="top">
                <Col span={24}>
                    { file && file.map((x: any) => {
                        console.log(x);
                        return <DocViewer
                            config={{ header: { disableHeader: true, disableFileName: true } }}
                            className="doc-viewer"
                            documents={[
                                {uri: API_URL + x.file, fileType: x.ext.toLowerCase()},
                            ]}
                            style={{width: window.innerWidth - 250, height: window.innerHeight - 150}}
                            pluginRenderers={DocViewerRenderers}
                        />
                    })}
                </Col>
            </Row>
            }
        </>
    );
};
