import {
    useForm,
    Form,
    Input,
    Select,
    Edit,
    useSelect, Checkbox, BooleanField, Switch,
} from '@pankod/refine-antd';
import {ITicketCategory, IUser} from 'interfaces';

export const TicketCategoryEdit: React.FC = () => {
    const { formProps, saveButtonProps, queryResult, formLoading, onFinish } = useForm<ITicketCategory>({
        }
    );

    return (
            <Edit saveButtonProps={saveButtonProps}>
                <Form {...formProps} layout="horizontal">
                    <Form.Item
                        label="Descrizione"
                        name="description"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            </Edit>
    );
};
