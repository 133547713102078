import {useShow, useOne, useList} from '@pankod/refine-core';
import {Button, DateField, List, Modal, Show, Space, Table, Typography} from '@pankod/refine-antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import {IDocument} from 'interfaces';
import DocViewer, {DocViewerRenderers} from 'react-doc-viewer';
import moment from 'moment';
import {useContext, useState} from 'react';
import { Fragment } from 'react';
import App from '../../App';
import {API_URL} from '../../constants';
import {UserContext} from '../../contexts/UserContext';

const { Title, Text } = Typography;

export const DocumentShow = () => {
    const {value} = useContext(UserContext);
    const [userPermission, setUserPermission] = value;
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const [show, setShow] = useState(false);
    const [file, setFile] = useState('');
    const { Column } = Table;
    const { data: documentData } = useOne<IDocument>({
        resource: "documents",
        metaData: {
            populate: ["category", "user", "document"],
        },
        id: record?.id || "",
        queryOptions: {
            enabled: !!record?.id,
        },
    });

    const {data: resultShow} = useList({
        resource: 'shows',
        config: {
            filters: [
                {
                    field: 'user][id]',
                    operator: 'eq',
                    value: documentData?.data.user.id
                }
            ]
        }
    })
    const handleCancel = () => {
        setShow(false);
    };

    const handleVisibleChange = (newVisible: boolean, file: string) => {
        setFile(file);
        setShow(newVisible);
        window.open(API_URL + file)
    };

    return (
        <>
            <Show isLoading={isLoading} canEdit={false}>
                <Title level={5}>Descrizione</Title>
                <Text>{documentData?.data.description}</Text>
                <Title level={5}>Categoria</Title>
                <Text>{documentData?.data.category.description}</Text>
                <Title level={5}>User</Title>
                <Text>{documentData?.data.user?.username}</Text>
                <hr/>
                <Table dataSource={documentData?.data.document}>
                    <Column title="File Name" dataIndex="name" key={Math.floor(Math.random() * (5 - 1 + 1)) + 1} />
                    <Column title="Creato" dataIndex="createdAt"  render={(value) => (
                        moment(value).format('DD-MM-YYYY')
                    )} />
                    <Column title="Modificato" dataIndex="updatedAt"  render={(value) => (
                        moment(value).format('DD-MM-YYYY')
                    )} />
                    <Column title="Dashboard" dataIndex="url"  render={(value) => {
                        const findFile = resultShow?.data.find((x: any) => x.file === value);
                        return (findFile ? 'SI' : 'NO')
                    }} />
                    <Column title="Azioni" dataIndex="url" render={(value) => (
                        <>
                            <Space>
                                <Button key={'btn1'} type="primary" shape="circle" icon={<SearchOutlined />} onClick={() => {handleVisibleChange(true, value)}}/>
                                {/*<Button key={'dtn2'} type="default" shape="circle" icon={<DownloadOutlined />} onClick={() => {handleVisibleChange(true, value)}}/>*/}
                            </Space>
                        </>
                    )}
                    />
                </Table>
            </Show>
            {/*<div hidden={!show}>*/}
            {/*    <Modal*/}
            {/*        title="Visualizzazione"*/}
            {/*        centered*/}
            {/*        visible={show}*/}
            {/*        onCancel={handleCancel}*/}
            {/*        footer={[*/}
            {/*            <Button key="back" onClick={handleCancel}>*/}
            {/*                Ok*/}
            {/*            </Button>*/}
            {/*        ]}*/}
            {/*        width={600}*/}
            {/*        style={{*/}
            {/*        textAlign: 'center'*/}
            {/*    }}>*/}
            {/*        <DocViewer*/}
            {/*            className="doc-viewer"*/}
            {/*            documents={[*/}
            {/*                { uri: file, fileType: 'png' },*/}
            {/*            ]}*/}
            {/*            style={{width: 500, height: 500}}*/}
            {/*            pluginRenderers={DocViewerRenderers}*/}
            {/*        />*/}
            {/*    </Modal>*/}
            {/*</div>*/}
        </>

    );
};
