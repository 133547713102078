import { useShow, useOne } from "@pankod/refine-core";
import { Show, Typography } from "@pankod/refine-antd";

import { ICategory } from "interfaces";

const { Title, Text } = Typography;

export const CategoryShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    console.log(record)

    const { data: categoryData } = useOne<ICategory>({
        resource: "categories",
        id: record?.id || "",
        queryOptions: {
            enabled: !!record?.id,
        },
    });

    return (
        <Show isLoading={isLoading}>
            <Title level={5}>Category</Title>
            <Text>{categoryData?.data.description}</Text>
        </Show>
    );
};
