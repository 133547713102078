import React, {FC, useContext, useEffect, useState} from 'react';
import { Typography } from "antd";

import {UserContext} from '../../contexts/UserContext';
import {apiFunction} from '../../apiFuntion';
const { Text } = Typography;
interface SelectProps {
    onSelect: () => void;
    theme?: "light" | "dark";
};

export const UserSelect: FC<SelectProps> = ( props ) => {
    const {value} = useContext(UserContext);
    const [userPermission, setUserPermission] = value;

    useEffect(() => {
        apiFunction.getUserData().then( (u: any) => {
            console.log(u);
            setUserPermission(u);
        });
    }, []);

    return (
        <Text style={{ color: props.theme === 'light' ? 'black' : 'whitesmoke'}}>{(userPermission?.description ? '' + userPermission?.description : 'Admin')}</Text>
    );
};
