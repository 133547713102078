import {
    useForm,
    Form,
    Input,
    Select,
    Edit,
    useSelect, Checkbox, BooleanField, Switch, Col, Row, Typography, Button, DateField, Icons, Space
} from '@pankod/refine-antd';
import {ITicket, ITicketRow, ITicketStatus} from '../../interfaces';
import {useCreate, useGetIdentity, useList, useNavigation, useUpdate} from '@pankod/refine-core';
import React from 'react';
import dayjs from 'dayjs';
import {API_URL} from '../../constants';

const {Title} = Typography;

export const TicketEdit: React.FC = () => {
    const {list} = useNavigation();
    const {data: identity} = useGetIdentity<{ id: number; fullName: string }>();
    const {formProps, saveButtonProps, id, queryResult, formLoading, onFinish} = useForm<ITicket>({
            metaData: {
                populate: 'ticket_rows.fromUser, ticket_rows.toUser, ticket_rows, ticket_rows.file, fromUser, ticket_category, ticket_priority, ticket_status'
            }
        }
    );

    const {selectProps: selectStatus, queryResult: ticketSatus} = useSelect<ITicketStatus>({
        resource: 'ticket-statuses',
        optionLabel: 'description',
        optionValue: 'id'
    })

    const updateTicket = useUpdate<ITicket>();
    const insertTicketRow = useCreate<ITicketRow>();
    const finish = (values: any) => {
        console.log(values);
        const statusClose: any = ticketSatus.data?.data.filter((x: any) => x.isClosed)[0];
        updateTicket.mutateAsync({
            resource: 'tickets',
            successNotification: false,
            id: Number(id),
            values: {
                ticket_status: values.ticket_status,
                updatedUser: identity?.id,
                isClosed: values.ticket_status === statusClose
            }
        }).then((res: any) => {
            console.log(res);
            insertTicketRow.mutateAsync({
                resource: 'ticket-rows',
                values: {
                    messaggio: values.risposta,
                    ticket: res.data.data.id,
                    fromUser: identity?.id,
                    toUser: queryResult?.data?.data.fromUser.id,
                }
            }).then(res => {
                console.log(res);
                queryResult?.refetch()
            })
        })
    }

    const download = (f: any) => {
        console.log(API_URL + f.url);
            const url = API_URL + f.url ;
    };

    return (
        <Edit
            actionButtons={<Button hidden={queryResult?.data?.data.ticket_status.isClose} type={'primary'} htmlType={'submit'} onClick={formProps.form?.submit}>Invia</Button>}
            title={'Oggetto Ticket: ' + queryResult?.data?.data.oggetto + ' - Data: '
                + dayjs(queryResult?.data?.data.dataApertura).format('MM/DD/YYYY') + ' - Utente: '
                + queryResult?.data?.data.fromUser.nome + ' - Stato: ' + queryResult?.data?.data.ticket_status.description}>
            <Form {...formProps}
                  layout="vertical"
                  onFinish={finish}
            >
                <hr/>
                <div style={{padding: 20, overflow: 'scroll', height: 360, backgroundColor: 'whitesmoke'}}>
                    {queryResult?.data?.data.ticket_rows.map((row: ITicketRow, i: number) => {
                        return (
                            <>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <>
                                            <div
                                                style={{textAlign: (row.fromUser && row.fromUser.id === identity?.id) ? 'right' : 'left'}}>
                                                <Title
                                                    level={5}>{i + 1}: {row.fromUser ? row.fromUser.nome : row.toUser.nome} -
                                                    Data: <DateField value={dayjs(row.createdAt)}
                                                                     format={'DD-MM-YYYY HH:mm'}/> </Title>
                                                <span><p>{row.messaggio}</p></span>
                                                <span hidden={!row.file}>Allegati:</span>
                                                {
                                                    row.file && row.file.length > 0 && row.file.map((f: any) => {
                                                        return (
                                                            <Space direction={'horizontal'}>
                                                                <Button type={'link'} size={'large'} icon={<Icons.DownloadOutlined />} href={API_URL + f.url} target={'_blank'} />
                                                            </Space>
                                                        )})
                                                }
                                            </div>
                                        </>
                                    </Col>
                                </Row>
                            </>
                        )
                    })}
                </div>

                <hr/>
                <div hidden={queryResult?.data?.data.ticket_status.isClose}>
                    <Form.Item
                        label="Risposta"
                        name="risposta"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input.TextArea/>
                    </Form.Item>
                    <Form.Item
                        label="Stato"
                        name="ticket_status"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select {...selectStatus} />
                    </Form.Item>

                </div>
            </Form>
        </Edit>
    );
};
