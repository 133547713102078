import {
    Create,
    Form,
    Input,
    Select, Upload,
    useForm,
    useSelect,
    getValueFromEvent, UploadProps, message, Row, Col, UploadFile
} from '@pankod/refine-antd';

import {ICategory, IDocument, IShow, IUser} from 'interfaces';
import {useApiUrl, useCreate, useGetIdentity} from '@pankod/refine-core';
import {
    InboxOutlined
} from '@ant-design/icons';
import {useState} from 'react';
import {API_URL} from '../../constants';
const { Dragger } = Upload;

export const DocumentCreate = () => {
    const apiUrl = useApiUrl();
    const [doc, setDoc] = useState<any[]>([]);
    const { data: identity } = useGetIdentity<{ id: number; fullName: string}>();
    const { formProps, saveButtonProps, onFinish } = useForm<IDocument>({
        metaData: {
            populate: ["user", "category", "document"],
        }
    });
    const { selectProps: categorySelectProps } = useSelect<ICategory>({
        resource: "categories",
        optionLabel: "description"
    });
    const { selectProps: userSelectProps } = useSelect<IUser>({
        resource: "users",
        optionLabel: "username"
    });
    const props: UploadProps = {
        name: 'files',
        multiple: true,
        action: API_URL + '/api/upload',
        onChange(info) {
            console.log(formProps.form)
            console.log(info);
            const { status } = info.file;
            if (status !== 'uploading') {
                // formProps.form?.setFieldValue("document", info.file.response);
            }
            if (status === 'done') {
                setDoc([...doc, ...info.file.response]);
                message.loading(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.loading(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const handleSubmit = async (values: any) => {
        let files = [];
        if (doc) {
            for (let f of doc) {
                files.push(f);
            }
        } else {

        }
        values.document = files;
        await onFinish(values);
    };
    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="horizontal" onFinish={handleSubmit}
                  colon={true}
                  wrapperCol={{span: 12, offset: 0}}
                  labelCol={{span: 4, offset: 1}}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="Category"
                            name={['category', 'id']}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...categorySelectProps} />
                        </Form.Item>
                        <Form.Item
                            label="User"
                            name={['user', 'id']}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...userSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="document"
                            valuePropName="fileList"
                            getValueFromEvent={getValueFromEvent}
                            noStyle
                        >
                            <div>
                                <Dragger {...props} style={{
                                    background: '#e28a74',
                                    border: 'none'
                                }}
                                         multiple={true}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined style={{
                                            color: '#000000'
                                        }}/>
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">
                                        Carica il documento da Associare!
                                    </p>
                                </Dragger>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};
