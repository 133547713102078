import {
    useForm,
    Form,
    Input,
    Select,
    Edit,
    useSelect, Checkbox, BooleanField, Switch, Col, Row, Typography, Show
} from '@pankod/refine-antd';
import {ITicket, ITicketRow} from '../../interfaces';
import {useList} from '@pankod/refine-core';
import React from 'react';
import dayjs from 'dayjs';
const {Title} = Typography;

export const TicketShow: React.FC = () => {
    const { formProps, saveButtonProps, id, queryResult, formLoading, onFinish } = useForm<ITicket>({
            metaData: {
                populate: 'ticket_rows, ticket_rows.file, fromUser, ticket_category, ticket_priority, ticket_status'
            }
        }
    );

    return (
            <Show
                  title={'Oggetto Ticket: ' + queryResult?.data?.data.oggetto + ' - Data: '
                      + dayjs(queryResult?.data?.data.dataApertura).format('MM/DD/YYYY HH:mm') + ' - Utente: '
                      + queryResult?.data?.data.fromUser.email}>
                <Form {...formProps} layout="horizontal">
                    { queryResult?.data?.data.ticket_rows.map( (row: ITicketRow) => {
                        return (<Row gutter={24}>
                            <Col span={6}>
                                Messaggio: {row.messaggio}
                            </Col>
                        </Row>)
                    })}

                    {/*<Form.Item*/}
                    {/*    label="Messaggio"*/}
                    {/*    name="ticket_rows"*/}
                    {/*    rules={[*/}
                    {/*        {*/}
                    {/*            required: true,*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*>*/}
                    {/*    <Input.TextArea />*/}
                    {/*</Form.Item>*/}
                </Form>
            </Show>
    );
};
