import {
    DateField,
    DeleteButton,
    EditButton, Icons,
    List, ShowButton, Space,
    Table, TagField,
    useTable
} from '@pankod/refine-antd';
import {ITicket} from '../../interfaces';
import dayjs from 'dayjs';
import {useGetIdentity} from '@pankod/refine-core';
import {apiFunction} from '../../apiFuntion';
import React, {useContext, useEffect} from 'react';
import {UserContext} from '../../contexts/UserContext';

export const TicketList: React.FC = () => {
    const {value} = useContext(UserContext);
    const [userPermission, setUserPermission] = value;
    const {tableProps, setFilters, filters, tableQueryResult} = useTable<ITicket>({
        metaData: {
            populate: '*'
        },
        queryOptions: {
            enabled: true
        },
        permanentFilter: [
            {
                field: 'fromUser][id]',
                operator: 'eq',
                value: userPermission?.group?.isUser ? userPermission.id : null
            }
        ],
        permanentSorter: [
            {
                field: 'dataApertura',
                order: 'desc'
            }
        ]
    });

    return (
        <List>
            { tableQueryResult && tableQueryResult.data && tableQueryResult.data.data && tableQueryResult.data.data.length > 0 &&
            <Table {...tableProps} rowKey="id" size={'small'}>
                <Table.Column
                    dataIndex="oggetto"
                    title="Oggetto"/>
                <Table.Column
                    dataIndex="dataApertura"
                    title="Data"
                    render={(value, record, index) => (<DateField value={dayjs(value)} format={'DD/MM/YYYY HH:mm'}/>)}
                />
                <Table.Column
                    dataIndex="ticket_category"
                    title="Categoria"
                    render={(value, record, index) => (value.description)}
                />
                <Table.Column
                    dataIndex="ticket_priority"
                    title="Priorità"
                    render={(value, record, index) => (
                        <TagField value={value.description} color={value.description === 'Bassa' ? 'red' : value.description === 'Media' ? 'yellow' : 'green'} />
                    )}
                />
                <Table.Column
                    dataIndex="ticket_status"
                    title="Stato"
                    render={(value, record, index) => (
                        <TagField value={value.description} color={value.isClose ? 'red' : value.isUpdate ? 'yellow' : 'green'} />
                    )}
                />
                <Table.Column<ITicket>
                    title="Actions"
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <Space>
                                <EditButton
                                    icon={<Icons.EyeOutlined />}
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                                {/*<ShowButton*/}
                                {/*    size="small"*/}
                                {/*    recordItemId={record.id}*/}
                                {/*    hideText*/}
                                {/*/>*/}
                                <DeleteButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
            }
        </List>
    );
};
