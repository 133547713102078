import {
  useGetIdentity,
} from "@pankod/refine-core";

export const Welcome: React.FC = () => {
  const { data: user } = useGetIdentity();

  const auth = localStorage.getItem('auth') || '{\"cognome\": \"\", \"nome\": \"\", \"id\": \"\"}';

  return (
      <>
        <img
            src="/images/logo.png"
            alt="Autelcom"
            style={{
              width: '150px',
              padding: '12px 24px',
              marginLeft: 20
            }}
        />
        <div style={{
          marginLeft: 22
        }}>
                        <span style={{
                          color: 'whitesmoke',
                          fontSize: 10
                        }}>Benvenuto</span>
          <p style={{
            color: 'white',
            fontSize: 16
          }}>{JSON.parse(auth)?.cognome + ' ' + JSON.parse(auth)?.nome}</p>
          <span style={{
            color: 'whitesmoke',
            fontSize: 10
          }}>Codice Cliente</span>
          <p style={{
            color: 'white',
            fontSize: 16
          }}>{JSON.parse(auth)['id']}</p>
        </div>
        <div>
          <hr style={{
            width: 200
          }}/>
        </div>
      </>
  );
};
