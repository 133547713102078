import {
    DeleteButton,
    EditButton,
    List, ShowButton, Space,
    Table,
    useTable
} from '@pankod/refine-antd';
import {ITicketStatus} from '../../interfaces';

export const TicketStatusList: React.FC = () => {
    const {tableProps} = useTable<ITicketStatus>({});

    return (
        <List>
            <Table {...tableProps} rowKey="id" size={'small'}>
                <Table.Column dataIndex="description" title="description"/>
                <Table.Column<ITicketStatus>
                    title="Actions"
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <Space>
                                <EditButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                                <DeleteButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};
