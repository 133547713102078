import {
    useForm,
    Form,
    Input,
    Select,
    Edit,
    useSelect, Checkbox, BooleanField, Switch,
} from '@pankod/refine-antd';
import {ITicketPriority} from '../../interfaces';
import {TicketPriorityCreate} from './create';



export const TicketPriorityEdit: React.FC = () => {
    const { formProps, saveButtonProps, queryResult, formLoading, onFinish } = useForm<ITicketPriority>({
        }
    );

    return (
            <Edit saveButtonProps={saveButtonProps}>
                <Form {...formProps} layout="horizontal">
                    <Form.Item
                        label="Descrizione"
                        name="description"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            </Edit>
    );
};
