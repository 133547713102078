import {
    useForm,
    Form,
    Input,
    Select,
    Edit,
    useSelect, getValueFromEvent, Upload, UploadProps, message, Row, Col, Popconfirm, Modal, Button, Icon, Icons,
} from '@pankod/refine-antd';
import {ICategory, IDocument, IShow, IUser} from 'interfaces';
import {InboxOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {useCreate, useList, useUpdate} from '@pankod/refine-core';
import {useEffect, useState} from 'react';
import {apiFunction} from '../../apiFuntion';
import {UPLOAD_URL} from '../../constants';

const {Dragger} = Upload;
const { confirm } = Modal;

export const DocumentEdit: React.FC = () => {
    const updateInfo = useUpdate<IDocument>();
    const updateShow = useUpdate<IShow>();
    const createShow = useCreate<IShow>();
    const [user, setUser] = useState<any>('');
    const {formProps, saveButtonProps, queryResult, formLoading, onFinish, id} = useForm<IDocument>({
            metaData: {
                populate: ['category', 'user', 'document'],
            }
        }
    );

    const listShow = useList<IShow>({
        resource: 'shows',
        queryOptions: {
            enabled: true
        },
        metaData: {
            populate: ["user"],
        },
        config: {
            filters: [
                {
                    field: "user][id]",
                    operator: "eq",
                    value: queryResult?.data?.data.user.id,
                },
            ],
        },
    });

    const {selectProps: categorySelectProps} = useSelect<ICategory>({
        resource: 'categories',
        defaultValue: queryResult?.data?.data?.category.id,
        optionLabel: 'description'
    });

    const {selectProps: userSelectProps} = useSelect<IUser>({
        resource: 'users',
        defaultValue: queryResult?.data?.data?.user?.id,
        optionLabel: 'username'
    });

    const handleSubmit = async (values: any) => {
        console.log(values);
        let files = [];
        for (let f of values.document) {
            if (f.hasOwnProperty('response')) {
                files.push(f.response[0]);
            } else {
                files.push(f);
            }
        }
        values.document = files;
        await onFinish(values);
    };

    const checkShow = async (file: string, ext: string, show: boolean) => {
        console.log(formProps.form?.getFieldValue('user').id)
        setUser(formProps.form?.getFieldValue('user').id);
            listShow.refetch().then( (u: any) => {
                // console.log(u);
                // console.log(file);
                if (u.isSuccess && u.data.data.length > 0) {
                    const findIdFile = u.data.data.find((c: any) => c.file === file);
                    // console.log(findIdFile);
                    if (findIdFile) {
                        console.log('Aggiorno')
                        updateShow.mutate({
                            resource: 'shows',
                            id: Number(findIdFile.id),
                            values: {
                                show: show,
                                file: file,
                                description: 'File da Mostrare',
                                ext: ext
                            }
                        })
                    } else {
                        console.log('Creo')
                        createShow.mutate({
                            resource: 'shows',
                            values: {
                                show: show,
                                file: file,
                                user: formProps.form?.getFieldValue('user').id,
                                description: 'File da Mostrare',
                                ext: ext
                            }
                        })
                    }
                } else {
                    console.log('Creo')
                    createShow.mutate({
                        resource: 'shows',
                        values: {
                            show: show,
                            file: file,
                            user: formProps.form?.getFieldValue('user').id,
                            description: 'File da Mostrare',
                            ext: ext
                        }
                    })
                }
            });
    };

    const showConfirm = async (values: any) => {
        console.log(formProps.form?.getFieldValue('user'))
                confirm({
                    icon: <ExclamationCircleOutlined />,
                    okText: 'Si',
                    cancelText: 'No',
                    content: <>
                        <span>Mostrare in Home?</span>
                        <p>Ricorda di salvare prima di Effettuare la scelta!</p>
                    </>,
                    onOk() {
                        console.log('OK');
                        updateInfo.mutate({
                            resource: 'documents',
                            id: Number(id),
                            values: {
                                urlToShow: values.url
                            }
                        });
                        checkShow(values.url, values.ext.toString().replace('.', ''), true);
                    },
                    onCancel() {
                        console.log('Cancel');
                        checkShow(values.url, values.ext.toString().replace('.', ''), false);
                    },
                });
    };

    const updateFileInfo = async (values: any) => {
        console.log(values)
        await showConfirm(values);
    }

    const props: UploadProps = {
        name: 'files',
        multiple: true,
        showUploadList: {
            showPreviewIcon: true,
            showRemoveIcon: true,
        },
        onPreview: updateFileInfo,
        action: UPLOAD_URL,
        onChange(info) {
            const {status} = info.file;
            if (status !== 'uploading') {
                // formProps.form?.setFieldValue("document", info.file.response);
            }
            if (status === 'done') {
                message.loading(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.loading(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        !formLoading ?
            <>
                <Edit saveButtonProps={saveButtonProps}>
                    <Form {...formProps} layout="horizontal" onFinish={handleSubmit}
                    >
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="User"
                            name={['user', 'id']}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...userSelectProps} />
                        </Form.Item>
                        <Form.Item
                            label="Category"
                            name={['category', 'id']}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...categorySelectProps} />
                        </Form.Item>
                        <Form.Item
                            name="document"
                            valuePropName="fileList"
                            getValueFromEvent={getValueFromEvent}
                            noStyle
                        >
                            <Dragger {...props} style={{
                                background: '#e28a74',
                                border: 'none'
                            }}
                                     multiple={true}
                                     itemRender={(value, file) => {
                                         const findFile = listShow.data?.data.find((x: any) => x.file === file.url);
                                         return (<span>{value}</span>)
                                     }}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined style={{
                                        color: '#000000'
                                    }}/>
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">
                                    Carica il documento da Associare!
                                </p>
                            </Dragger>
                        </Form.Item>

                    </Form>
                </Edit>
            </> : <></>
    );
};
