import {Refine} from '@pankod/refine-core';
import {
    notificationProvider,
    ReadyPage,
    ErrorComponent, Layout, Anchor, Title, AuthPage
} from '@pankod/refine-antd';
import '@pankod/refine-antd/dist/styles.min.css';
import routerProvider from '@pankod/refine-react-router-v6';
import {DataProvider} from '@pankod/refine-strapi-v4';
import {useTranslation} from 'react-i18next';
import {Header} from 'components/layout';
import {authProvider, axiosInstance} from './authProvider';
import {API_URL, FRONT_URL} from './constants';
import {Login} from './pages/login';
import {UserList} from './pages';
import {UserEdit} from './pages/users/edit';
import {UserCreate} from './pages/users/create';
import {DocumentList} from './pages/documents';
import {DocumentCreate, DocumentEdit} from './pages/documents';
import {CategoryCreate, CategoryList, CategoryShow} from './pages/categories';
import {CategoryEdit} from './pages/categories/edit';
import {DocumentShow} from './pages/documents/show';
import {apiFunction} from './apiFuntion';
import {IUser} from './interfaces';
import {DashboardPage} from './pages/dashboard';
import {Welcome} from './components/welcome';
import {TicketCategoryCreate, TicketCategoryEdit, TicketCategoryList} from './pages/ticket-categories';
import {TicketStatusCreate, TicketStatusEdit, TicketStatusList} from './pages/ticket-statuses';
import {TicketPriorityCreate, TicketPriorityEdit, TicketPriorityList} from './pages/ticket-priorities';
import {TicketCreate, TicketEdit, TicketList} from './pages/tickets';
import {TicketShow} from './pages/tickets/show';
import {UserProvider} from './contexts/UserContext';
import './style.css';
import React from 'react';
const {Link} = routerProvider;
const {RouterComponent} = routerProvider;

const App: React.FC = () => {
    const {t, i18n} = useTranslation();
    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    return (
        <UserProvider>
            <Refine
                notificationProvider={notificationProvider}
                disableTelemetry
                Layout={Layout}
                ReadyPage={ReadyPage}
                catchAll={<ErrorComponent/>}
                routerProvider={{
                    ...routerProvider,
                    routes: [
                        {
                            path: "/forgot-password",
                            element: <AuthPage type="forgotPassword" />,
                        },
                        {
                            path: "/reset-password",
                            element: <AuthPage type="updatePassword" />,
                        },
                    ],
                }}
                authProvider={authProvider}
                dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
                LoginPage={Login}
                Title={({collapsed}) => (
                    <>
                        <Link to="/">
                            {collapsed ? (
                                <>
                                    <img
                                        src="/images/logo.png"
                                        alt="Autelcom"
                                        style={{
                                            width: '80px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '8px 16px',
                                        }}
                                    />
                                </>
                            ) : (
                                <Welcome/>
                            )}
                        </Link>
                    </>
                )}
                i18nProvider={i18nProvider}
                Header={Header}
                DashboardPage={DashboardPage}
                accessControlProvider={{
                    can: async ({resource, action, params}) => {
                        const res: IUser = await apiFunction.getUserData();
                        // console.log(resource, action, res);
                        // if ((   action === 'delete' ||
                        //         action === 'create' ||
                        //         action === 'edit') &&
                        //     !res.group.isAdmin) {
                        //     return Promise.resolve({
                        //         can: false,
                        //         reason: 'Authorized',
                        //     });
                        // }
                        if (resource === 'users' && (action === 'list' || action === 'create' || action === 'edit' || action === 'delete' || action === 'show') && res.group.isAdmin) {
                            return Promise.resolve({
                                can: true,
                                reason: 'Authorized',
                            });
                        }
                        if (resource === 'documents' && (action === 'list' || action === 'create' || action === 'edit' || action === 'delete' || action === 'show') && res.group.isAdmin) {
                            return Promise.resolve({
                                can: true,
                                reason: 'Authorized',
                            });
                        }
                        if (resource === 'categories' && (action === 'list' || action === 'create' || action === 'edit' || action === 'delete' || action === 'show') && res.group.isAdmin) {
                            return Promise.resolve({
                                can: true,
                                reason: 'Authorized',
                            });
                        }
                        if (resource === 'ticket-categories' && (action === 'list' || action === 'create' || action === 'edit' || action === 'delete' || action === 'show') && res.group.isAdmin) {
                            return Promise.resolve({
                                can: true,
                                reason: 'Authorized',
                            });
                        }
                        if (resource === 'ticket-statuses' && (action === 'list' || action === 'create' || action === 'edit' || action === 'delete' || action === 'show') && res.group.isAdmin) {
                            return Promise.resolve({
                                can: true,
                                reason: 'Authorized',
                            });
                        }
                        if (resource === 'ticket-priorities' && (action === 'list' || action === 'create' || action === 'edit' || action === 'delete' || action === 'show') && res.group.isAdmin) {
                            return Promise.resolve({
                                can: true,
                                reason: 'Authorized',
                            });
                        }
                        if (resource === 'tickets' && (action === 'edit' || action === 'list') && res.group.isAdmin) {
                            return Promise.resolve({
                                can: true,
                                reason: 'Authorized',
                            });
                        }
                        if (resource === 'tickets' && (action === 'edit' || action === 'list' || action === 'create') && res.group.isUser) {
                            return Promise.resolve({
                                can: true,
                                reason: 'Authorized',
                            });
                        }
                        if (resource === 'documents' && (action === 'list' || action === 'show') && res.group.isUser) {
                            return Promise.resolve({
                                can: true,
                                reason: 'Authorized',
                            });
                        }
                        if (resource === 'dashboard') {
                            return Promise.resolve({
                                can: true,
                                reason: 'Authorized',
                            });
                        }
                        if (resource === 'logout') {
                            return Promise.resolve({
                                can: true,
                                reason: 'Authorized',
                            });
                        }

                        return Promise.resolve({can: false});
                    },
                }}
                resources={[
                    {
                        name: 'users',
                        list: UserList,
                        edit: UserEdit,
                        create: UserCreate
                    },
                    {
                        name: 'tickets',
                        list: TicketList,
                        edit: TicketEdit,
                        create: TicketCreate,
                        show: TicketShow
                    },
                    {
                        name: 'ticket-categories',
                        list: TicketCategoryList,
                        edit: TicketCategoryEdit,
                        create: TicketCategoryCreate
                    },
                    {
                        name: 'ticket-statuses',
                        list: TicketStatusList,
                        edit: TicketStatusEdit,
                        create: TicketStatusCreate
                    },
                    {
                        name: 'ticket-priorities',
                        list: TicketPriorityList,
                        edit: TicketPriorityEdit,
                        create: TicketPriorityCreate
                    },
                    {
                        name: 'documents',
                        list: DocumentList,
                        create: DocumentCreate,
                        edit: DocumentEdit,
                        show: DocumentShow
                    },
                    {
                        name: 'categories',
                        list: CategoryList,
                        create: CategoryCreate,
                        edit: CategoryEdit,
                        show: CategoryShow
                    }
                ]}
            />
        </UserProvider>
    );
}

export default App;
