import {
    List,
    TextField,
    TagField,
    DateField,
    Table,
    FilterDropdown,
    Select,
    ShowButton,
    Space,
    EditButton,
    useTable, useSelect, DeleteButton,
} from '@pankod/refine-antd';

import {IGroup, IUser} from 'interfaces';
import {useMany} from '@pankod/refine-core';

export const UserList: React.FC = () => {
    const { tableProps } = useTable<IUser>({
        metaData: {
            populate: ["group"],
        },
    });
    const groupIds =
        tableProps?.dataSource?.map((item) => item.group.id) ?? [];
    const { data: groupsData, isLoading } = useMany<IGroup>({
        resource: "groups",
        ids: groupIds,
        queryOptions: {
            enabled: groupIds.length > 0,
        },
    });
    const { selectProps: groupSelectProps } = useSelect<IGroup>({
        resource: "groups",
    });
    return (
        <List>
            <Table {...tableProps} rowKey="id" size={'small'}>
                <Table.Column dataIndex="username" title="username" />
                <Table.Column dataIndex="email" title="email" />
                <Table.Column
                    dataIndex={["group", "id"]}
                    title="group"
                    render={(value) => {
                        if (isLoading) {
                            return <TextField value="Loading..." />;
                        }

                        return (
                            <TextField
                                value={
                                    groupsData?.data.find(
                                        (item) => item.id === value,
                                    )?.description
                                }
                            />
                        );
                    }}
                />
                <Table.Column
                    dataIndex="createdAt"
                    title="createdAt"
                    render={(value) => <DateField format="LLL" value={value} />}
                />
                <Table.Column<IUser>
                    title="Actions"
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <Space>
                                <EditButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                                <DeleteButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};
