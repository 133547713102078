import {
    useForm,
    Form,
    Input,
    Select,
    Edit,
    useSelect, Checkbox, BooleanField, Switch, Col, Row,
} from '@pankod/refine-antd';
import {IGroup, IUser} from 'interfaces';
import {useEffect, useState} from 'react';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

export const UserEdit: React.FC = () => {
    const [check, setCheck] = useState(false);
    const { formProps, saveButtonProps, queryResult, formLoading, onFinish } = useForm<IUser>({
            metaData: {
                populate: ["group"],
            }
        }
    );

    const { selectProps: groupSelectProps } = useSelect<IGroup>({
        resource: "groups",
        defaultValue: queryResult?.data?.data?.group.id,
        optionLabel: "description"
    });

    useEffect( () => {
        console.log()
    })

    const onChange = (list: CheckboxValueType[]) => {
        setCheck(true);
    };

    return (
        !formLoading ?
            <Edit saveButtonProps={saveButtonProps}>
                <Form {...formProps} layout="horizontal"
                      colon={true}
                      labelAlign={'left'}
                      wrapperCol={{span: 16, offset: 0}}
                      labelCol={{span: 6, offset: 0}} size={'middle'}>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label="Ragione Sociale"
                                name="ragioneSociale"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Sede Legale"
                                name="sedeLegale"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Sede Operativa"
                                name="sedeOperativa"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Nome"
                                name="nome"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Cognome"
                                name="cognome"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Telefono"
                                name="telefono"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Cellulare"
                                name="cellulare"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Partita Iva"
                                name="piva"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Pec"
                                name="pec"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Please add a password' },
                                    { min: 8, message: 'Password must have a minimum length of 8' },
                                    {
                                        pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
                                        message: 'Password must contain at least one lowercase letter, uppercase letter, number, and special character'
                                    }]}
                            >
                                <Input type={'password'} />
                            </Form.Item>
                            <Form.Item
                                label="Group"
                                name={["group", "id"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select {...groupSelectProps} />
                            </Form.Item>
                            <Form.Item
                                label="Confirmed"
                                name="confirmed"
                                valuePropName={"checked"}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Edit> : <></>
    );
};
