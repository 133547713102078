import {
    Button,
    DateField, DatePicker, DeleteButton,
    EditButton, Form, Input,
    List, Popover,
    ShowButton,
    Space,
    Table,
    Icons,
    TextField,
    Select,
    useTable, useSelect
} from '@pankod/refine-antd';
import {ICategory, IDocument, IGroup, IUser} from '../../interfaces';
import {useGetIdentity, useMany} from '@pankod/refine-core';
import {useEffect, useState} from 'react';
import {apiFunction} from '../../apiFuntion';
const { RangePicker } = DatePicker;

export const DocumentList: React.FC = () => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const { data: identity } = useGetIdentity<{ id: number}>();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [loadData, setLoadData] = useState<boolean>(false);
    const [user, setUser] = useState<any>('');
    const { tableProps, searchFormProps, tableQueryResult } = useTable<IDocument>({
        queryOptions: {
            enabled: true
        },
        metaData: {
            populate: ["document", "user", "category"],
        },
        permanentFilter: [
            {
                field: "user][id]",
                operator: "eq",
                value: user,
            }
        ],
    });

    useEffect( () => {
        apiFunction.getUserData(localStorage.getItem('strapi-jwt-token')).then( (res: IUser) => {
            if (res.group.isAdmin) {
                setUser('')
                setIsAdmin(true);
            } else {
                setUser(res.id)
            }
            data();
        })
    }, [])

    const categoryIds =
        tableProps?.dataSource?.map((item) => item?.category?.id) ?? [];
    const { data: categoriesData, isLoading } = useMany<ICategory>({
        resource: "categories",
        ids: categoryIds,
        queryOptions: {
            enabled: categoryIds.length > 0,
        },
    });

    const { selectProps: categorySelectProps } = useSelect<ICategory>({
        resource: "categories",
        optionLabel: "description"
    });

    const { selectProps: userSelectProps } = useSelect<IUser>({
        resource: "users",
        optionLabel: "username"
    });

    const resetFilter = () => {
        form.resetFields();
    };

    const data = () => {
        tableQueryResult.refetch().then();
    }
    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };

    const handlerUser = (e: any) => {
        setUser(e);
    };

    return (
        <List>
            <Popover
                placement="leftTop"
                style={{
                    float: "right"
                }}
                content={<Form form={form} layout="horizontal" {...searchFormProps} labelCol={{ span: 8 }}
                               wrapperCol={{ span: 16 }}>
                    <Form.Item label="Descrizione" name="description">
                        <Input
                            placeholder="Descrizione"
                            prefix={<Icons.SearchOutlined />}
                            // onChange={(e) => setDescription(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item label="User" name="user">
                        <Select {...userSelectProps}
                                allowClear={true}
                                placeholder="User"
                                onChange={(e) => handlerUser(e)}
                        />
                    </Form.Item>
                    <Form.Item label="Category" name="category">
                        <Select {...categorySelectProps}
                            allowClear={true}
                            placeholder="Categoria"
                            // onChange={(e) => setCategory(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item label="Created At" name="createdAt">
                        <RangePicker />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button htmlType="button" type="primary" onClick={data}>
                                Filter
                            </Button>
                            <Button htmlType="button" type="primary" onClick={resetFilter}>
                                Reset
                            </Button>
                        </Space>

                    </Form.Item>
                </Form>}
                title="Custom Filter"
                trigger="click"
                visible={visible}
                onVisibleChange={handleVisibleChange}
            >
                <Button type="primary" style={{marginBottom: 15}} hidden={!isAdmin}>Filter</Button>
            </Popover>
            <Table {...tableProps} rowKey="id" size={'small'}>
                <Table.Column dataIndex="description" title="description" />
                {/*<Table.Column dataIndex="document" title="files" render={(value) => (*/}
                {/*    value.map((res: any) => {*/}
                {/*        return <p>{res.name}</p>*/}
                {/*    })*/}
                {/*)}/>*/}
                <Table.Column
                    dataIndex={["category", "id"]}
                    title="category"
                    render={(value) => {
                        if (isLoading) {
                            return <TextField value="Loading..." />;
                        }

                        return (
                            <TextField
                                value={
                                    categoriesData?.data.find(
                                        (item) => item.id === value,
                                    )?.description
                                }
                            />
                        );
                    }}
                />
                <Table.Column
                    dataIndex="createdAt"
                    title="createdAt"
                    render={(value) => <DateField format="LLL" value={value} />}
                />
                <Table.Column<IUser>
                    title="Actions"
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <Space>
                                <ShowButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                                <EditButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                                <DeleteButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};
