import {AuthProvider, useNavigation} from '@pankod/refine-core';
import { AuthHelper } from "@pankod/refine-strapi-v4";

import {TOKEN_KEY, API_URL, FRONT_URL} from './constants';

import axios from "axios";
import {message} from '@pankod/refine-antd';

export const axiosInstance = axios.create();
const strapiAuthHelper = AuthHelper(API_URL + "/api");

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const { data, status } = await strapiAuthHelper.login(username, password);
    if (status === 200) {
      localStorage.setItem(TOKEN_KEY, data.jwt);
      localStorage.setItem('auth', JSON.stringify(data.user));
      localStorage.setItem('userId', JSON.stringify(data.user.id));
      // set header axios instance
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${data.jwt}`,
      };

      return Promise.resolve();
    }
    return Promise.reject();
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem('userId');
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
      };
      return Promise.resolve();
    }

    return Promise.reject();
  },
  forgotPassword: async ({ email }) => {
    // You can handle the reset password process according to your needs.
      axios.post(API_URL + '/api/auth/forgot-password', {
        email: email
      }).then( res => {
        console.log(res);
        message.info('Controlla la tua casella di posta!');
        setTimeout(() => {
          window.open(FRONT_URL + '/login', '_self');
        },  3000)
        return {
          success: true,
        };
      })
  },
  updatePassword: async ({code, password, confirmPassword}) => {
    console.log(code, password)
    // You can handle the reset password process according to your needs.
    axios.post(API_URL + '/api/auth/reset-password', {
      code: code,
      password: password,
      passwordConfirmation: confirmPassword
    }).then( res => {
      console.log(res);
      message.info('Password Reimpostata Correttamente!');
      setTimeout(() => {
        window.open(FRONT_URL + '/login', '_self');
      },  3000)
      return {
        success: true,
      };
    })
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    const { data, status } = await strapiAuthHelper.me(token);
    if (status === 200) {
      const { id, username, email } = data;
      return Promise.resolve({
        id,
        username,
        email,
      });
    }

    return Promise.reject();
  },
};
